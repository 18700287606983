import React, {Component} from 'react';
import DocumentTitle from 'react-document-title';


export default class HtmlPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iFrameHeight: '0px'
        }
    }

    render() {
        let title = "文章飞走了～";
        let html_url = "";
        const params = new URLSearchParams(window.location.search);
        const url = params.get('url');
        //  console.log(url);
        if (url != null) {
            //   console.log(url.split("/"));
            const v1 = url.split("/");
            if (v1.length >= 5) {
                let titles = v1[4].split("-");
                if (titles.length > 2) {
                    title = titles[0];
                    html_url = url;
                }
            }
        }
        const onChange = () => {
            const iframes = document.getElementsByName("doubi")
            console.log(iframes);
            console.log(iframes[0]);
            let img = iframes[0].childNodes
            console.log(img);


            this.setState({
                "iFrameHeight": 4100 + 'px'
            });
        };
        return (
            <DocumentTitle title={title}>
                <div class="article">
                    <h4 class="title_h4">{title}</h4>
                    <iframe name="doubi" ref="iframe" scrolling="no" frameBorder="0"
                            style={{
                                width: "100%",
                                height: this.state.iFrameHeight
                            }}
                            onLoad={() => {//iframe高度不超过content的高度即可
                                let h = document.documentElement.clientHeight - 20;
                                this.setState({
                                    "iFrameHeight": h + 'px'
                                });
                                onChange();
                            }}
                            src={html_url}
                    />
                </div>

            </DocumentTitle>
        )
    }
}
