import React from 'react';
import DocumentTitle from 'react-document-title';

function Retransmission() {
    return (
        <DocumentTitle title="橘兜-转载声明">
            <div className="home-page">
                <div className="home-page-wrapper1">
                    <br/>
                    <h1>橘兜-转载声明</h1>
                    <br/>
                    <p>更新日期：2022年09月09日</p>
                    <p>生效日期：2022年09月09日</p>
                    <br/>
                    <br/>
                    {/*<h3>引言</h3>*/}
                    <p style={{fontSize: 18}}>
                        感谢您对橘兜原创内容的青睐。如需转载或引用本文所述内容的任何文字、图片、音频或视频，请注明出处。转载前请与橘兜取得联系并经同意，转载时须注明来源及作者，并不得对本文内容进行实质性修改及/或删减。
                    </p>
                    <br/>
                    <br/>
                    <p> 转载联系邮箱：<a
                        href="mailto:judou@doubiai.com">judou@doubiai.com</a>、橘兜「小纸条」系统。
                    </p>
                    <p>如需转载橘兜非原创内容，请自行联系原作者取得转载许可。</p>
                    <br/>
                </div>
            </div>
        </DocumentTitle>
    );
}

export default Retransmission;