import React from 'react';
import DocumentTitle from 'react-document-title';

function Relief() {
    return (
        <DocumentTitle title="橘兜-免责声明">
            <div className="home-page">
                <div className="home-page-wrapper1">
                    <br/>
                    <h1>橘兜-免责声明</h1>
                    <br/>
                    <p>更新日期：2022年09月09日</p>
                    <p>生效日期：2022年09月09日</p>
                    <br/>
                    <br/>
                    {/*<h3>引言</h3>*/}
                    <p style={{fontSize: 18}}>
                        本文所述内容皆以交流分享为目的，仅供参考，不构成任何市场预测、判断，或投资、咨询建议。市场有风险，投资需谨慎。橘兜及/或其关联人员对本文所述内容的准确性、可靠性、时效性及完整性不作任何明示或暗示的保证，并提醒您对本文所述内容结合自身情况进行独立评估，依据或使用本文内容所造成的任何后果，橘兜及/或其关联人员均不承担任何形式的责任。

                        若您认为本文及／或转载内容可能侵犯著作权人的合法权益，请及时与橘兜取得联系。橘兜将在收到反馈后，尽快移除相关内容。</p>
                    <br/>
                    <br/>
                    <p> 联系邮箱：<a
                        href="mailto:judou@doubiai.com">judou@doubiai.com</a>
                    </p>
                </div>
            </div>
        </DocumentTitle>
    );
}

export default Relief;
